import { LoadingOverlay } from "@mantine/core";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  const { isLogged, isLoading } = useSelector((state) => state.auth);
  if (isLoading) {
    return (
      <LoadingOverlay
        loaderProps={{ size: "sm", color: "blue" }}
        overlayOpacity={0.3}
        overlayColor="#c5c5c5"
        visible
      />
    );
  }
  if (!isLogged) {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  return children;
};

export default PrivateRoute;
