import {
  logout,
  userLoading,
  userLoadFailed,
  userLoaded,
} from "../actions/auth";
import { api } from "../api/index";
import { GetCookie, RemoveCookie } from "../../utils/cookie";

export const Logout = () => async (dispatch: any) => {
  try {
    RemoveCookie("refresh_token");
    dispatch(logout());
  } catch (error) {
    dispatch(logout());
    RemoveCookie("refresh_token");
    // toast.error('Неизвестная ошибка');
  }
};

export const LoadUser = () => async (dispatch: any) => {
  dispatch(userLoading());
  let language = localStorage.getItem("language");
  if (!language) {
    language = "tkm";
    localStorage.setItem("language", "tkm");
  }
  let token = GetCookie("refresh_token");
  try {
    if (token) {
      const response = await api.get({ url: "/api/auth/load-user", token });
      dispatch(userLoaded(response.data));
    } else {
      dispatch(userLoadFailed());
    }
  } catch (error) {
    dispatch(userLoadFailed());
    // toast.error('Неизвестная ошибка');
  }
};
