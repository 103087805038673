import { Suspense, useEffect, lazy } from "react"; //useState
import { Route } from "react-router-dom"; //, Routes
import PrivateRoute from "./components/PrivateRoute";
import { useDispatch } from "react-redux";
import { LoadUser } from "./application/middlewares/auth";

import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import CustomRoutes from "./components/CustomRouter";
import { LoadingOverlay } from "@mantine/core";

// Containers
const Layout = lazy(() => import("./Layout"));

// Pages
const Login = lazy(() => import("./pages/login"));

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(LoadUser()); //eslint-disable-next-line
  }, []);
  return (
    <MantineProvider withNormalizeCSS withGlobalStyles>
      <Notifications />
      <Suspense
        fallback={
          <LoadingOverlay
            loaderProps={{ size: "sm", color: "blue" }}
            overlayOpacity={0.3}
            overlayColor="#c5c5c5"
            visible
          />
        }
      >
        <CustomRoutes>
          {/* <Routes> */}
          <Route path="login" element={<Login />} />
          <Route
            path="/*"
            element={
              <PrivateRoute>
                <Layout />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<div>Not found</div>} />
          {/* </Routes> */}
        </CustomRoutes>
      </Suspense>
    </MantineProvider>
  );
}

export default App;
