export const GET_HELPER_DATA = "GET_HELPER_DATA";
export const setHelperData = (data: any) => ({
  type: GET_HELPER_DATA,
  payload: data,
});

export const SET_SERVICE = "SET_SERVICE";
export const setService = (service: string) => ({
  type: SET_SERVICE,
  payload: service,
});
