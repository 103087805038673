import { GET_HELPER_DATA, SET_SERVICE } from "../actions/data";

const initialState = {
  role_list: [],
  status_list: [],
  service: "production",
};

const reducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case GET_HELPER_DATA:
      return {
        ...state,
        role_list: action.payload.role_list,
        status_list: action.payload.status_list,
      };
    case SET_SERVICE:
      return {
        ...state,
        service: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
