import { useEffect, useState } from "react";
import { Routes, useLocation } from "react-router-dom";

const CustomRoutes = ({ children }) => {
  const [progress, setProgress] = useState(false);
  const [prevLoc, setPrevLoc] = useState("");
  const location = useLocation();

  useEffect(() => {
    setPrevLoc(location.pathname);
    setProgress(true);
    if (location.pathname === prevLoc) {
      setPrevLoc("");
    } // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (progress === true) {
      setProgress(false);
    } // eslint-disable-next-line
  }, [prevLoc, progress]);

  return <Routes>{children}</Routes>;
};

export default CustomRoutes;
